import { Card, Select, Spin, Table, Tooltip } from "antd";
import MyButton from "components/ui/Button/MyButton";
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ImagePaths } from "utils/ImagePath";
import { getEmployeeList } from "./employeeMigrationApi";
import ToastMsg from "components/common/ToastMsg";
import { REPORTING_MANAGER_LIST } from "constants";

// var MigratedData = [
//     {
//         "id": 101,
//         "emp_code": "EMP1234",
//         "first_name": "John",
//         "last_name": "Doe",
//         "designation": {
//             "id": 1,
//             "name": "Software Engineer"
//         },
//         "department": {
//             "id": 10,
//             "name": "Engineering"
//         }
//     },
//     {
//         "id": 102,
//         "emp_code": "EMP5678",
//         "first_name": "Jane",
//         "last_name": "Smith",
//         "designation": {
//             "id": 2,
//             "name": "Project Manager"
//         },
//         "department": {
//             "id": 11,
//             "name": "Management"
//         }
//     }
// ]


function ReportingManagerConfig() {
    const authtoken = sessionStorage.getItem("token");
    const [loadings, setLoadings] = useState(false);
    const [dropdownList, setDropDownList] = useState({
        departmentlist: [],
        reportingManagerList: [],
    });
    const [selectedDepart, setSelectedDepart] = useState(null);
    const [selectedManager, setSelectedManager] = useState(null);
    const [filteredRepMan, setFilteredRepMan] = useState("");
    const [unassignedData, setUnassignedData] = useState([]);
    const [selectedDetails, setSelectedDetails] = useState([]);
    const [assignedData, setAssignedData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [departmentError, setDepartmentError] = useState(false);
    const [RepManError, setRepManError] = useState(false);
    const navigate = useNavigate();
    const hideCursorRef = useRef(null);
    const location = useLocation();
    const { migration_id } = location.state || {};

    useEffect(() => {
        getDepartmentList();
        employee();
        getMigratedList();
    }, []);

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const getDepartmentList = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    departmentlist: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    departmentlist: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                departmentlist: []
            }))
        }
    }

    const employee = async () => {
        try {
            const apiData = await getEmployee(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    reportingManagerList: [...apiData.data]
                }))
            } else {
                setDropDownList((prevFilterData) => ({
                    ...prevFilterData,
                    reportingManagerList: []
                }))
            }
        } catch (error) {
            setDropDownList((prevFilterData) => ({
                ...prevFilterData,
                reportingManagerList: []
            }))
        }
    };

    const getMigratedList = async () => {
        try {
            setLoadings(true)
            const apiData = await getEmployeeList(`id=${migration_id}`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setUnassignedData([...apiData.data])
                setLoadings(false)
            } else {
                setLoadings(false)
                setUnassignedData([])
            }
        } catch (error) {
            setLoadings(false)
            setUnassignedData([])
        }
    };

    const columns = [
        { title: 'Employee Code', dataIndex: 'emp_code', key: 'emp_code' },
        { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
        { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            render: (designation) => designation?.name
        },
    ];

    const dataSource = [...unassignedData];

    const rowSelection = {
        selectedRowKeys,
        onSelect: (record, selected) => {
            let updatedAssets;
            if (selected) {
                updatedAssets = [
                    ...selectedDetails,
                    record
                ];
            } else {
                updatedAssets = selectedDetails.filter(
                    (item) => item.id !== record.id
                );
            }
            setSelectedDetails(updatedAssets);
            setSelectedRowKeys(updatedAssets.map(item => item.id));
        },
        onSelectAll: (selected, selectedRows) => {
            if (selected) {
                setSelectedDetails(selectedRows);
                setSelectedRowKeys(selectedRows.map(item => item.id));
            } else {
                setSelectedDetails([]);
                setSelectedRowKeys([]);
            }
        },
        getCheckboxProps: (record) => ({
            disabled: assignedData.some((item) => item.id === record.id),
        }),
    };

    const Second_columns = [
        {
            title: 'Emp Code', dataIndex: 'emp_code', key: 'emp_code', sorter: true,
            fixed: "left", render: (_, record) => (<div>{record?.emp_code}</div>)
        },
        {
            title: 'First Name', dataIndex: 'first_name', key: 'first_name', sorter: true,
            fixed: "left", render: (_, record) => (<div>{record?.first_name}</div>)
        },
        {
            title: 'Last Name', dataIndex: 'last_name', key: 'last_name', sorter: true,
            fixed: "left", render: (_, record) => (<div>{record?.last_name}</div>)
        },
        {
            title: 'Designation', dataIndex: 'designation', key: 'designation', sorter: true,
            fixed: "left", render: (designation) => (<div>{designation?.name}</div>)
        },
        {
            title: 'Department', dataIndex: 'department', key: 'department', sorter: true,
            fixed: "left", render: (department) => (<div>{department?.department_name}</div>)
        },
        {
            title: 'Reporting Manager', dataIndex: 'reporting_manager', key: 'reporting_manager', sorter: true,
            fixed: "left", render: (reporting_manager) => (<div>{`${reporting_manager?.first_name} ${reporting_manager?.last_name}`}</div>)
        },
    ];

    const rowClassName = (record) => {
        return assignedData.some((item) => item.id === record.id) ? 'highlight-disabled-row' : '';
      };

    const filterOption = (input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    const handleBackCancel = useCallback(() => {
        navigate('/datamigration/employeemigration');
    }, [navigate])

    const handleAdd = () => {
        if (!selectedDepart) {
            setDepartmentError(true);
        } else if (!selectedManager) {
            setRepManError(true);
        } else {
            const updatedDetails = selectedDetails.map(detail => ({
                ...detail,
                department: dropdownList?.departmentlist.find(item => item?.id === selectedDepart),
                reporting_manager: dropdownList?.reportingManagerList.find(item => item?.id === selectedManager),
            }));

            setAssignedData(prevAssignedData => [...prevAssignedData, ...updatedDetails]);

            setSelectedRowKeys([]);
            setSelectedDetails([]);
            setDepartmentError(false);
            setRepManError(false);
        }
    };

    const handleCancel = () => {
        setSelectedDetails([]);
        setSelectedRowKeys([]);
    };

    const handleclear = () => {
        setAssignedData([])
    }

    const handlesave = useCallback(() => {
        let methods = "PUT";
        const authtoken = sessionStorage.getItem("token");
    
        const updatedFormData = assignedData.map(item => ({
            id: item?.id,
            reporting_manager_id: item?.reporting_manager?.id
        }));
    
        setLoadings(true);
    
        fetch(REPORTING_MANAGER_LIST, {
            method: methods,
            headers: {
                'Authorization': `token ${authtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedFormData)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data.status === "success") {
                ToastMsg("success", data.message);
                handleBackCancel()
            } else {
                ToastMsg("error", data.message || "Failed to save data");
            }
            setLoadings(false);
        })
        .catch(error => {
            setLoadings(false);
            ToastMsg("error", error.message || "An error occurred");
        });
    }, [assignedData, setLoadings]);    

    const styles = {
        fltContainer: {
            width: "54%",
            backgroundColor: "white",
            padding: "0.5vw"
        },
    }

    return (
        <div className="h-full">
            {loadings && (
                <div className="loaderOverlay">
                    <Spin />
                </div>
            )}
            <Card className="p-2">
                <div className="flex flex-row justify-between items-center">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className='flex items-center'>
                            <Tooltip title={"Back"} className="mr-2" placement="bottom">
                                <img style={{ cursor: "pointer" }} src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
                            </Tooltip>
                            <p className='common_tltTxt' style={{ fontSize: "1.75vw" }}>{`Reporting Manager Configuration`}</p>
                        </div>
                        <div className="flex flex-row mt-5 ml-1">
                            <div style={{ width: "20vw" }}>
                                <p className="common_dayWiseTltTxt pb-1">Department {selectedDetails.length > 0 ? <span className='requiredTxt'>*</span> : null}</p>
                                <Select
                                    showSearch
                                    size="small"
                                    style={{ width: "100%" }}
                                    status={departmentError ? "error" : null}
                                    value={selectedDepart ? selectedDepart : ""}
                                    name={"Department"}
                                    onChange={(value) => {
                                        let filteredData = dropdownList.reportingManagerList.filter((item) => item?.department_id[0]?.id == value)
                                        setSelectedDepart(value)
                                        setSelectedManager("")
                                        setDepartmentError(false)
                                        setFilteredRepMan(filteredData)
                                    }}
                                    filterOption={filterOption}
                                    ref={hideCursorRef}
                                    onSelect={() => {
                                        hideCursorRef.current.blur();
                                    }}
                                >
                                    {dropdownList.departmentlist.map((option) => (
                                        <Select.Option key={option?.id} value={option?.id}>{option?.department_name}</Select.Option>
                                    ))}
                                </Select>
                                {departmentError ? <div style={{ fontSize: "0.6vw", color: "red" }}>This field is required</div> : null}
                            </div>
                            <div className="ml-5" style={{ width: "20vw" }}>
                                <p className="common_dayWiseTltTxt pb-1">Reporting Manager {selectedDetails.length > 0 ? <span className='requiredTxt'>*</span> : null}</p>
                                <Select
                                    showSearch
                                    size="small"
                                    style={{ width: "100%" }}
                                    status={RepManError ? "error" : null}
                                    value={selectedManager ? selectedManager : ""}
                                    name={"reporting_manager"}
                                    onChange={(value) => {
                                        let selectedData = dropdownList.reportingManagerList.filter((item) => item?.id == value)
                                        let filteredData = dropdownList.departmentlist.filter((item) => item?.id == selectedData[0]?.department_id[0]?.id)
                                        setSelectedManager(value)
                                        setRepManError(false)
                                        setSelectedDepart(filteredData[0]?.id)
                                    }}
                                    filterOption={filterOption}
                                    ref={hideCursorRef}
                                    onSelect={() => {
                                        hideCursorRef.current.blur();
                                    }}
                                >
                                    {filteredRepMan.length > 0 && filteredRepMan?.map((option) => (
                                        <Select.Option key={option.id} value={option.id}>
                                            {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                        </Select.Option>
                                    ))}
                                </Select>
                                {RepManError ? <div style={{ fontSize: "0.6vw", color: "red" }}>This field is required</div> : null}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "#d8f7f2", padding: "1vw 2.5vw 1vw 2.5vw", fontFamily: "Urbanist" }}>
                        <div style={{ fontSize: "1.7vw", fontWeight: 600 }}>{`${assignedData ? assignedData.length : 0}/${unassignedData ? unassignedData.length : 0}`}</div>
                        <p style={{ fontSize: "1vw", fontWeight: 500, color: "#616161" }}>Migrated Status</p>
                    </div>
                </div>
            </Card>

            <div className="flex h-full" style={{ width: "100%" }}>
                <div className="w-full mt-2" style={{ width: "45%", backgroundColor: "white", padding: "0.5vw" }}>
                    <div style={{ border: "1px solid #cac9c9", height: "60vh" }}>
                        <Table
                            rowKey={(record) => record?.id}
                            rowSelection={rowSelection}
                            dataSource={dataSource}
                            columns={columns}
                            scroll={{ y: `calc(${"68vh"} - 140px)` }}
                            pagination={false}
                            rowClassName={rowClassName}
                        />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: "0.7vw" }}>
                        <MyButton key="cancel" label="Cancel" onClick={() => handleCancel()} outlined={true} paddingX={"0 0.5vw"} marginRight={"0.625vw"} />
                        <MyButton key="add" label={"Add"} disabled={selectedDetails?.length <= 0} onClick={() => handleAdd()} paddingX={"0 1vw"} />
                    </div>
                </div>
                <div className="w-full mt-2 ml-2" style={styles.fltContainer}>
                    <div style={{ border: "1px solid #cac9c9", height: "60vh" }} >
                        <Table
                            columns={Second_columns}
                            dataSource={assignedData?.length > 0 ? assignedData : []}
                            scroll={{ y: `calc(${"68vh"} - 140px)` }}
                            tableLayout="fixed"
                            pagination={false}
                            key="table"
                        />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: "0.7vw" }}>
                        <MyButton key="clear" label="Clear" onClick={() => handleclear()} outlined={true} paddingX={"0 0.5vw"} marginRight={"0.625vw"} />
                        <MyButton key="Save Draft" label={"Save"} onClick={() => handlesave()} paddingX={"0 1vw"} />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ReportingManagerConfig;