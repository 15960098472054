import { Card, DatePicker, Upload } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Select, Checkbox, Button, Spin} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import dayjs from "dayjs";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import { getEmployeeShift } from "hooks/api/configurationApi/employeeShiftApi";
import { genderEnum, relationshipEnum, titleEnum, bloodGroupEnum } from "components/common/enum";
import ToastMsg from "components/common/ToastMsg";
import { getEmployee, getPostalPincode } from "hooks/api/employeeApi/employeeInformationApi";
import {getEmployeeGroup} from "hooks/api/masterApi/employeeGroupApi";
import { getAssetRegistry } from "hooks/api/assetManagementApi/assetRegistryApi";
import {
  BlankSpacesValidator,
  emailValidator,
  getEighteenYearsAgoDate,
  noBlankSpacesValidator,
  validateAlphabetsOnly,
  validateBankAccountNumber,
  validateFNAlphabetsOnly,
  validateIFSCCode,
  validateNumericMax10Digits,
  validateMobile10Digit,
  validateNumericMax6Digits,
  disabledPastDate,
  userValidator,
  passwordValidator,
  emailValidation,
  disabledFutureDate,
  notAllowAlphaCaps,
  allowAlphaCapsNum,
} from "components/common/validation";
import {getUserGroup} from "hooks/api/adminApi/userGroupApi";
import { getEmployeeType } from "hooks/api/masterApi/employeeTypeApi";
import { imgUploadSize } from 'components/common/enum';
import locale from 'antd/es/date-picker/locale/en_US';

export const PersonalInfo = ({ formData, updateFormData, onSave, onBack, updateWholeFormData, isEdit, employeeId, isLoading, isScrLoading }) => {
  const [rManager, setRManager] = useState([]);
  const [eDept, setEDept] = useState();
  const [eDesg, setEDesg] = useState([]);
  const [eShift, setEShift] = useState([]);
  const [eGroup, setEGroup] = useState([]);
  const [eType, setEType] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [assetList, setAssetList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [form] = Form.useForm();
  const isSuperAdmin = JSON.parse(sessionStorage.getItem("is_super_admin"));
  const isAdmin = JSON.parse(sessionStorage.getItem("is_admin"));
  const [initialFormData, setInitialFormData] = useState(null);
  
  const isEditAccess = (isSuperAdmin || isAdmin);
  const hideCursorRef = useRef({});
  const hideAssetRegRef = useRef(null);
  const hideHandByRef = useRef(null);

  // api calls

  const employeeDept = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEDept([...apiData.data])
      } else {
        setEDept([])
      }
    } catch (error) {
      setEDept([])
    }
  }

  const employeeDesg = async () => {
    try {
      const apiData = await getEmployeeDesignation(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEDesg([...apiData.data])
      } else {
        setEDesg([])
      }
    } catch (error) {
      setEDesg([])
    }
  };

  const employeeShift = async () => {
    try {
      const apiData = await getEmployeeShift(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEShift([...apiData.data])
      } else {
        setEShift([])
      }
    } catch (error) {
      setEShift([])
    }
  };

  const employee = async () => {
    setRManager([])
    try {
      const apiData = await getEmployee(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setRManager([...apiData.data])
      } else {
        setRManager([])
      }
    } catch (error) {
      setRManager([])
    }
  };

  const employeeGroup = async () => {
    setEGroup([])
    try {
      const apiData = await getEmployeeGroup(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEGroup(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setEGroup([])
      }
    } catch (error) {
      setEGroup([])
    }
  };

  const getUserGroupList = async () => {
    setUserGroup([])
    try {
      const apiData = await getUserGroup(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setUserGroup(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setUserGroup([])
      }
    } catch (error) {
      setUserGroup([])
    }
  };

  const getAssetList = async () => {
    setAssetList([])
    try {
      const apiData = await getAssetRegistry(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setAssetList(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setAssetList([])
      }
    } catch (error) {
      setAssetList([])
    }
  };

  const getEmployeeTypeList = async () => {
    setEType([])
    try {
      const apiData = await getEmployeeType(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEType(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setEType([])
      }
    } catch (error) {
      setEType([])
    }
  };
  
  useEffect(() => {
    employeeDept();
    employeeDesg();
    employeeShift();
    employeeGroup();
    employee();
    getUserGroupList();
    getAssetList();
    getEmployeeTypeList();
  }, []);

  useEffect(() => {
    if (!initialFormData && formData.professional_info.employee_id !== "") {
      setInitialFormData(formData);
    }
  }, [initialFormData, formData.professional_info.employee_id]);

  useEffect(() => {
    if (Object.entries(formData).length > 0 && formData !== undefined) {
      let image_url = (!Array.isArray(formData?.professional_info?.profile_document) &&
        (formData?.professional_info?.profile_document &&
          typeof formData?.professional_info?.profile_document === "string")) ? formData?.professional_info?.profile_document : "";

      let isFileList = Array.isArray(formData?.professional_info?.profile_document) ? formData?.professional_info?.profile_document : [];
      if (isFileList.length > 0) {
        const uploadedFile = formData?.professional_info?.profile_document[0].originFileObj;
        image_url = URL.createObjectURL(uploadedFile);
      }
      form.setFieldsValue({
        ...formData,
        profile: isFileList,
        professional_info: {
          ...formData.professional_info,
          date_of_joining: formData?.professional_info?.date_of_joining ? formData?.professional_info?.date_of_joining : undefined,
          date_of_relieving: formData?.professional_info?.date_of_relieving ? formData?.professional_info?.date_of_relieving : undefined,
        },
        personal_info: {
          ...formData.personal_info,
          dob: formData?.personal_info?.dob ? formData?.personal_info?.dob : undefined,
        },
        asset_info: formData.asset_info?.length > 0 && formData.asset_info.map(item => ({
          ...item,
          issued_date: item?.issued_date ? item?.issued_date : undefined,
        })),
        user_creation_info:{...formData.user_creation_info},
      })
      setImageUrl(image_url);
      setProfileList(isFileList);
    }
  }, [formData])

  const handleImgChange = (fileList) => {
    if(fileList?.length > 0 && fileList[0]?.originFileObj){
    const uploadedFile = fileList[0].originFileObj;
    const imgUrl = URL.createObjectURL(uploadedFile);
    form.setFields([
      {
        name: "profile",
        value: uploadedFile ? [uploadedFile] : [],
      }
    ]);
    setProfileList(uploadedFile ? [uploadedFile] : []);
    setImageUrl(imgUrl);
    updateFormData("professional_info", "profile_document", fileList);
  }};

  const disabledDOBDate = (current) => {
    // Can not select days before today and today
    return current && current >= getEighteenYearsAgoDate();
  };

  const profileProps = {
    onRemove: (file) => {
      const index = profileList.indexOf(file);
      const newFileList = profileList.slice();
      newFileList.splice(index, 1);
      setProfileList(newFileList);
    },
    beforeUpload: (file) => {
      const isLt2M = file.size / 1024 / 1024 < imgUploadSize;
      if (!isLt2M) {
        ToastMsg("error", `Image must smaller than ${imgUploadSize}MB!`);
        return false;
      }
      setProfileList([...profileList, file]);
      return false;
    },
    profileList,
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined style={{fontSize: "1vw"}}/> : <PlusOutlined style={{fontSize: "1vw"}}/>}
      <div style={{fontSize: "1vw", marginTop: 8}}>Upload</div>
    </div>
  );

  const triggerFieldValidation = (statekey, fieldName) => {
    form.validateFields([[statekey, fieldName]]);
  };

  const handleInputChange = (statekey, name, value) => {
    form.setFields([
      {
        name: [statekey, name],
        value: value,
      }
    ]);
    updateFormData(statekey, name, value);
    triggerFieldValidation(statekey, name);
    if(name === 'shift' && isEdit && initialFormData.professional_info.shift !== undefined){
     ToastMsg("warning", 'Changed shift will reflect tomorrow');
    }

    if ((name === 'pin_code' || name === 'present_pin_code') && value.length === 6) {
      getPostalPincodList(value, name);
    } else if ((name === 'pin_code' || name === 'present_pin_code') && value.length !== 6) {
      handleClearPincode(name);
    }
  };

  const getPostalPincodList = async (pincode, name) => {
    try {
      const res = await getPostalPincode(pincode);
      const apiData = res[0];

      if (apiData && apiData.Status === "Success" && apiData.PostOffice) {
        const { Country, District, State } = apiData.PostOffice[0];
        if (name === 'pin_code') {
          form.setFields([
            {
              'country': ["personal_info", "country"], value: Country,
              'city': ["personal_info", "city"], value: District,
              'state': ["personal_info", "state"], value: State,
            }
          ]);
          updateFormData("personal_info", "country", Country);
          updateFormData("personal_info", "city", District);
          updateFormData("personal_info", "state", State);
        } else {
          form.setFields([
            {
              'present_country': ["personal_info", "present_country"], value: Country,
              'present_city': ["personal_info", "present_city"], value: District,
              'present_state': ["personal_info", "present_state"], value: State,
            }
          ]);
          updateFormData("personal_info", "present_country", Country);
          updateFormData("personal_info", "present_city", District);
          updateFormData("personal_info", "present_state", State);
        }
      } else if (apiData.Status === "Error") {
        handleClearPincode(name);
        ToastMsg("error", apiData.Message);
      }
    } catch (error) {
      handleClearPincode(name);
      ToastMsg("error", "No records found");
    }
  };

  const handleClearPincode = (name) => {
    if (name === 'pin_code') {
      form.setFields([
        { "country": ["personal_info", "country"], value: "" },
        { "city": ["personal_info", "city"], value: "" },
        { "state": ["personal_info", "state"], value: "" },
      ]);
      updateFormData("personal_info", "country", "");
      updateFormData("personal_info", "city", "");
      updateFormData("personal_info", "state", "");
    } else {
      form.setFields([
        { "present_country": ["personal_info", "present_country"], value: "" },
        { "present_city": ["personal_info", "present_city"], value: "" },
        { "present_state": ["personal_info", "present_state"], value: "" },
      ]);
      updateFormData("personal_info", "present_country", "");
      updateFormData("personal_info", "present_city", "");
      updateFormData("personal_info", "present_state", "");
    }
  };

  const handleKeyChange = (e) => {
    // Allow only numbers, backspace, and delete keys
    if (!/^\d$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
      e.preventDefault();
    }
  }
  
  const renderInput = (label, statekey, name, maxLength = 150, minLength = null, required = false) => {
    return (
      <div>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Input
        autoComplete= {name === "user_name" ? "new-password" : 'off'}
        disabled = {name === "employee_id" || !isEditAccess}
        type= {"text"}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        value={formData[statekey][name] ? formData[statekey][name] : ""}
        onChange={(e) => handleInputChange(statekey, e.target.name, e.target.value)}
        onKeyDown={name === 'email_address'? notAllowAlphaCaps : name === "employee_id" ? handleKeyChange : name === "ifsc_code"? allowAlphaCapsNum : null}
      />
      </ div>  
    )
  }

  const renderSelect = (label, statekey, name, list = [], showKey = "text", valueKey = "value", required = false) => {
    if (!hideCursorRef.current[statekey]) {
      hideCursorRef.current[statekey] = React.createRef();
    }
    return (
      <div>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <Select
        showSearch
        value={formData[statekey][name] ? formData[statekey][name] : ""}
        name={name}
        onChange={(value) => handleInputChange(statekey, name, value)}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled = {!isEditAccess}
        ref={hideCursorRef.current[statekey]}
        onSelect={() => {
          hideCursorRef.current[statekey].current.blur();
        }}
      >
        {list?.length > 0 && list.map((option) => (
          ((valueKey === "id" && (option.is_active === true || option.id === formData[statekey][name])) || valueKey !== "id") ?
          <Select.Option key={option[valueKey]} value={option[valueKey]}>
              {name === "reporting_manager" ?
                `${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - "+ option.employee_id : ""}`
                : option[showKey]}
          </Select.Option> : null
        ))}
      </Select>
      </div>
    )
  }

  const handleAssetInfoChange = (name, index, value) => {
    let newItems = [...formData.asset_info];
    newItems[index][name] = value;
    updateWholeFormData("asset_info", newItems);
    form.setFields([
      {
        name: ['asset_info', index, name],
        value: (name === "issued_date") ? dayjs(value, "DD-MM-YYYY") : value,
        errors: undefined,
      }
    ]);
  };

  const renderAssetInput = (label, index, item, statekey, isDisabled) => {
    return (
      <>
      <div className='commonInputTlt'>{label}</div>
      <Input
        disabled={isDisabled || !isEditAccess}
        value={item[statekey] ? item[statekey] : ""}
        maxLength={150}
        onChange={(e) => handleAssetInfoChange(statekey, index, e.target.value)}
      />
      </>
    )
  }

  const handleDateChange = (dateString, statekey, name) => {
    updateFormData(statekey, name, dateString);
    form.setFields([
      {
        name: [statekey, name],
        value:  dateString ? dateString : undefined,
        errors: undefined,
      }
    ]);
  };

  const renderDatePicker = (label, statekey, name, disabledDates = disabledPastDate, required = false) => {
    return (
      <>
      <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
      <DatePicker
        disabled = {name === "date_of_relieving" ? !(isEdit === true && isEditAccess)  : !isEditAccess}
        allowClear
        inputReadOnly={true}
        locale={locale}
        style={{ width: "100%" }}
        name={name}
        format={"DD-MM-YYYY"}
        value={formData[statekey][name] ? dayjs(formData[statekey][name]) : undefined}
        onChange={(date) => handleDateChange(date, statekey, name)}
        disabledDate={disabledDates}
        placeholder = ""
      />
      </>
    )
  }

  const handleAdd = () => {
    const allPreviousLevelsFilled = formData.asset_info.every((item) => {
      return item.asset_registry && item.asset_name && item.handing_over_by && item.issued_date;
    });
    let isDuplicate = checkForDuplicates(formData.asset_info);
    if (allPreviousLevelsFilled && isDuplicate === "") {
      const newItems = [...formData.asset_info,
      { asset_registry: "", asset_name: "", handing_over_by: "", issued_date: null, remarks: "" },];
      updateWholeFormData("asset_info", newItems);
    } else {
      ToastMsg("error", isDuplicate ? isDuplicate : "Please fill all previous levels before adding a new one.");
    }
  };

  const handleRemove = (index) => {
    const newItems = [...formData.asset_info];
    newItems.splice(index, 1);
    updateWholeFormData("asset_info", newItems);
  };

  const disabledDateFrom = current => {
    let {date_of_joining} = formData.professional_info || {};
    const startDate = dayjs(date_of_joining);
    if (startDate) {
      return current && current < startDate.endOf('day');
    }
    return current && current > dayjs().endOf('day');
  };

  const checkForDuplicates = (assetItem) => {
    let msg = ""
    const encounteredIds = new Set();
    for (const item of assetItem) {
      const { asset_registry } = item;
      if (encounteredIds.has(asset_registry)) {
        msg = "Same Asset Found";
      }
      encounteredIds.add(asset_registry);
    }
    return msg;
  };

  const handleFinish = (values) => {
    if (isLoading) return; // Prevent multiple submissions
    let isDuplicate = values?.asset_info?.length >= 2 ? checkForDuplicates(values.asset_info) : "";
    if (isDuplicate !== "") {
      ToastMsg("error", isDuplicate);
      return;
    }
    onSave();
  }

  const [selectedAssetRegistryIds, setSelectedAssetRegistryIds] = useState([]);
  
  useEffect(() => {
    setSelectedAssetRegistryIds(formData.asset_info.map(item => item.asset_registry));
  }, [formData.asset_info]);

  return (
    <Form
      layout="vertical"
      form={form}
      requiredMark={false}
      name="basicform"
      onFinish={handleFinish}
      style={{ height: "100%" }}
    >
      <div className="grid grid-cols-12 gap-2" style={{ height: "92%" }}>
        {isScrLoading && (
          <div className="loaderOverlay">
            <Spin />
          </div>
        )}
        <Card className="col-span-5 h-full overflow-x-hidden overflow-y-auto">
            <div className="flex justify-between items-center" style ={{height: "1.8vw"}}>
            <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Profile Upload</p>
            <Form.Item name={['professional_info', 'is_active']}>
            <Checkbox
              checked={formData.professional_info.is_active}
              disabled = {!(isEdit === true && isEditAccess)}
              onChange={(e) => handleInputChange("professional_info", "is_active", e.target.checked)}
            >
              Active
            </Checkbox>
           </Form.Item>
            </div>
            <hr />
              <Form.Item
                name="profile"
                fileList={profileList}
                defaultValue={[profileList]}
                style={{textAlign: "center"}}
              >
                <div className="grid grid-cols-1 justify-center items-center" style={{ height: "128px", borderBottom: "1px solid #cbcbcb" }}>
                <Upload
                disabled = {!isEditAccess}
                style={{width: "100%", height: "100%"}}
                  {...profileProps}
                  listType="picture-circle"
                  showUploadList={false}
                  onChange={({fileList}) => handleImgChange(fileList)}
                  fileList={profileList}
                  accept=".jpg,.jpeg,.png"
                  maxCount={1}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (uploadButton)}
                </Upload>
                </div>
              </Form.Item>
            <p id="common_weekoffTxt" style={{ textAlign: "left", marginTop: "4px" }}>Professional Information</p>
            <div className="grid grid-cols-2 gap-x-4">
             {(isEdit || employeeId) ? <Form.Item
                name={['professional_info', 'employee_id']}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                {renderInput("Employee Id", "professional_info", "employee_id", 10, null, true)}
              </Form.Item> : null}
              <Form.Item name={['professional_info', 'display_name']}>
                {renderInput("Display Name", "professional_info", "display_name", 50)}
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left" }}
                name={["professional_info", "department"]}
                rules={[{ required: true, message: "This field is required" }]}
              >
                {renderSelect("Department", "professional_info", "department", eDept, "department_name", "id", true)}
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left" }}
                name={["professional_info", "designation"]}
                rules={[{ required: true, message: "This field is required" }]}
              >
                {renderSelect("Designation", "professional_info", "designation", eDesg, "designation_name", "id", true)}
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left" }}
                name={["professional_info", "reporting_manager"]}
              >
                {renderSelect("Reporting Manager", "professional_info", "reporting_manager", rManager, "first_name", "id")}
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left" }}
                name={["professional_info", "shift"]}
                rules={[{ required: true, message: "This field is required" }]}
              >
                {renderSelect("Shift", "professional_info", "shift", eShift, "shift_name", "id", true)}
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left" }}
                name={["professional_info", "employee_group"]}
              >
                {renderSelect("Employee Group", "professional_info", "employee_group", eGroup, "employee_group_name", "id")}
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left" }}
                name={["professional_info", "employee_type"]}
                rules={[{ required: true, message: "This field is required" }]}
              >
                {renderSelect("Employee Type", "professional_info", "employee_type", eType, "employee_type_name", "id", true)}
              </Form.Item>
              <Form.Item
                style={{ textAlign: "left" }}
                name={["professional_info", "work_location"]}
              >
                {renderInput("Work Location", "professional_info", "work_location", 50)}
              </Form.Item>
              <Form.Item name={["professional_info", "date_of_joining"]} rules={[{ required: true, message: "This field is required" }]}>
                {renderDatePicker("Date of Joining", "professional_info", "date_of_joining", disabledFutureDate, true)}
              </Form.Item>
              <Form.Item name={["professional_info", "date_of_relieving"]}>
                {renderDatePicker("Date of Relieving", "professional_info", "date_of_relieving", disabledDateFrom)}
              </Form.Item>
            </div>
        </Card>
        <div className="col-span-7 h-full overflow-y-scroll">
          <Card>
            <div className="w-full flex justify-between items-center" style ={{height: "1.8vw"}}>
            <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Personal Information</p>
            <Form.Item name={['personal_info', 'is_admin']}>
            <Checkbox
              checked={formData.personal_info.is_admin}
              disabled = {!isEditAccess}
              onChange={(e) => handleInputChange("personal_info", "is_admin", e.target.checked)}
            >
              Admin
            </Checkbox>
           </Form.Item>
            </div>
            <div className="grid grid-cols-3 gap-x-5 gap-y-3" style={{ marginTop: "0.625vw" }}>
              <Form.Item
                style={{ textAlign: "left" }}
                name={[ "personal_info", "title"]}
                rules={[{ required: true, message: "This field is required" },]}
              >
                {renderSelect("Title", "personal_info", "title", titleEnum, "text", "value", true)}
              </Form.Item>
              <Form.Item
                name={[ "personal_info", "first_name"]}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                  { validator: validateFNAlphabetsOnly },
                  { validator: noBlankSpacesValidator }
                ]}
                style={{ width: "100%" }}
              >
                {renderInput("First Name", "personal_info", "first_name", 50, null, true)}
              </Form.Item>
              <Form.Item
                name={[ "personal_info", "last_name"]}
                rules={[
                  { required: true, message: "This field is required" },
                  { validator: validateAlphabetsOnly },
                  { validator: BlankSpacesValidator }
                ]}
              >
                {renderInput("Last Name", "personal_info", "last_name", 50, null, true)}
              </Form.Item>
              <Form.Item name={[ "personal_info", "gender"]} style={{ textAlign: "left" }}>
                {renderSelect("Gender", "personal_info", "gender", genderEnum)}
              </Form.Item>
              <Form.Item name={["personal_info", "blood_group"]} style={{ textAlign: "left" }}>
                {renderSelect("Blood Group", "personal_info", "blood_group", bloodGroupEnum)}
              </Form.Item>
              <Form.Item name={[ "personal_info", "dob"]}>
                {renderDatePicker("Date of Birth", "personal_info", "dob", disabledDOBDate)}
              </Form.Item>
              <Form.Item
                name={[ "personal_info", "mobile_number"]}
                rules={[
                  {
                    required: true,
                    validator: validateMobile10Digit,
                  },
                ]}
              >
                {renderInput("Personal Mobile No", "personal_info", "mobile_number", 10, null, true)}
              </Form.Item>
              <Form.Item
                name={[ "personal_info", "email_address"]}
                rules={[
                  { required: true, message: "This field is required" },
                  { validator: emailValidation },
                ]}
              >
                {renderInput("Personal Email ID", "personal_info", "email_address", 150, null, true)}
              </Form.Item>
              <div class="col-span-3 text-left">
                <Form.Item
                  name={[ "personal_info", "permanent_address"]}
                  rules={[
                    {
                      required: false,
                      message: "",
                      validator: BlankSpacesValidator,
                    },
                  ]}
                >
                  {renderInput("Present Address", "personal_info", "permanent_address")}
                </Form.Item>
              </div>
              <Form.Item
                name={[ "personal_info", "pin_code"]}
                rules={[
                  {
                    required: false,
                    validator: validateNumericMax6Digits,
                  },
                ]}
              >
                {renderInput("Pincode", "personal_info", "pin_code", 6)}
              </Form.Item>
              <div class="text-left">
                <Form.Item
                  name={[ "personal_info", "city"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                      validator: noBlankSpacesValidator,
                    },
                  ]}
                >
                  {renderInput("City", "personal_info", "city", 50, null, true)}
                </Form.Item>
              </div>

              <div class="text-left">
                <Form.Item
                  name={[ "personal_info", "state"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                      validator: noBlankSpacesValidator,
                    },
                  ]}
                >
                  {renderInput("State", "personal_info", "state", 50, null, true)}
                </Form.Item>
              </div>
              <Form.Item
                name={[ "personal_info", "country"]}
                rules={[
                  {
                    required: false,
                    message: "",
                    validator: BlankSpacesValidator,
                  },
                ]}
              >
                {renderInput("Country", "personal_info", "country", 50)}
              </Form.Item>
              <Form.Item name={[ "personal_info", "contact_person"]}>
                {renderInput("Contact Person Name", "personal_info", "contact_person")}
              </Form.Item>
              <Form.Item
                name={[ "personal_info", "relationship"]}
                style={{ textAlign: "left" }}
              >
                {renderSelect("Relationship Type", "personal_info", "relationship", relationshipEnum)}
              </Form.Item>
              <Form.Item name={[ "personal_info", "contact_person_mobile_number"]}
                rules={[{validator: validateNumericMax10Digits}]}
              >
                {renderInput("Contact Person Mobile No", "personal_info", "contact_person_mobile_number", 10)}
              </Form.Item>
              {/*emergency contact starts*/}
              <div class="col-span-3 text-left">
                <Form.Item
                  name={["personal_info", "present_address"]}
                  rules={[
                    {
                      required: false,
                      message: "",
                      validator: BlankSpacesValidator,
                    },
                  ]}
                >
                  {renderInput("Permanent Address", "personal_info", "present_address")}
                </Form.Item>
              </div>
              <Form.Item
                name={["personal_info", "present_pin_code"]}
                rules={[
                  {
                    required: false,
                    validator: validateNumericMax6Digits,
                  },
                ]}
              >
                {renderInput("Pincode", "personal_info", "present_pin_code", 6)}
              </Form.Item>
              <div class="text-left">
                <Form.Item
                  name={["personal_info", "present_city"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                      validator: noBlankSpacesValidator,
                    },
                  ]}
                >
                  {renderInput("City", "personal_info", "present_city", 50, null, true)}
                </Form.Item>
              </div>

              <div class="text-left">
                <Form.Item
                  name={["personal_info", "present_state"]}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                      validator: noBlankSpacesValidator,
                    },
                  ]}
                >
                  {renderInput("State", "personal_info", "present_state", 50, null, true)}
                </Form.Item>
              </div>
              <Form.Item
                name={["personal_info", "present_country"]}
                rules={[
                  {
                    required: false,
                    message: "",
                    validator: BlankSpacesValidator,
                  },
                ]}
              >
                {renderInput("Country", "personal_info", "present_country", 50)}
              </Form.Item>
              <Form.Item name={["personal_info", "emergency_contact_person"]}>
                {renderInput("Emergency Contact Person", "personal_info", "emergency_contact_person")}
              </Form.Item>
              <Form.Item
                name={["personal_info", "emergency_contact_person_relationship"]}
                style={{ textAlign: "left" }}
              >
                {renderSelect("Relationship Type", "personal_info", "emergency_contact_person_relationship", relationshipEnum)}
              </Form.Item>
              <Form.Item name={["personal_info", "emergency_contact_person_mobile_number"]}
                rules={[{ validator: validateNumericMax10Digits }]}
              >
                {renderInput("Emergency Contact No", "personal_info", "emergency_contact_person_mobile_number", 10)}
              </Form.Item>
            </div>
          </Card>
          <div className="pt-3">
            <Card>
              <p id="common_weekoffTxt" style={{ textAlign: "left" }}>User Creations</p>
              <div className="grid grid-cols-3 gap-x-5 gap-y-3">
                <Form.Item
                  name={["user_creation_info", "user_name"]}
                  rules={[
                    { required: true, message: 'This field is required' },
                    {
                      validator: async (_, value) => await userValidator(_, value, form, employeeId),
                    },
                  ]}
                >
                  {renderInput("Username", "user_creation_info", "user_name", 50, null, true)}
                </Form.Item>
                <Form.Item
                  name={["user_creation_info", "password"]}
                  rules={[{required: true, validator: passwordValidator}]}
                >
                  <div className='commonInputTlt'>Password <span className='requiredTxt'>*</span></div>
                  <Input.Password
                    autoComplete="new-password"
                    disabled = {!isEditAccess}
                    type="text"
                    name={"password"}
                    value={formData.user_creation_info.password ? formData.user_creation_info.password : ""}
                    onChange={(e) => handleInputChange("user_creation_info", e.target.name, e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === ' ') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name={["user_creation_info", "user_group"]}
                  rules={[{ required: true, message: "This field is required" }]}>
                  {renderSelect("Select UserGroup", "user_creation_info", "user_group", userGroup, "user_group_name", "id", true)}
                </Form.Item>
                <Form.Item
                  name={["user_creation_info", "email_address"]}
                  rules={[
                    { required: true, message: 'This field is required' },
                    {
                      validator: async (_, value) => await emailValidator(_, value, form, employeeId),
                    },
                  ]}
                >
                  {renderInput("Professional Email ID", "user_creation_info", "email_address", 150, null, true)}
                </Form.Item>
              </div>
            </Card>
          </div>
          <div className="pt-3">
            <Card>
              <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Account Information</p>
              <div className="grid grid-cols-3 gap-x-5 gap-y-3">
                <Form.Item
                  name={[ "bank_info", "bank_name"]}
                  rules={[
                    {
                      required: false,
                      validator: BlankSpacesValidator,
                    },
                  ]}
                >
                  {renderInput("Bank Name", "bank_info", "bank_name")}
                </Form.Item>
                <Form.Item
                  name={[ "bank_info", "account_number"]}
                  rules={[{ required: false, },
                  { validator: validateBankAccountNumber, }
                  ]}
                >
                  {renderInput("Account Number", "bank_info", "account_number", 18, 9)}
                </Form.Item>
                <Form.Item name={[ "bank_info", "ifsc_code"]}
                  rules={[
                    { required: false },
                    { validator: validateIFSCCode }
                  ]}>
                  {renderInput("IFSC Code", "bank_info", "ifsc_code", 11)}
                </Form.Item>
                <Form.Item
                  name={["bank_info", "account_holder_name"]}
                  rules={[
                    {
                      required: false,
                      validator: validateAlphabetsOnly,
                      validator: BlankSpacesValidator,
                    },
                  ]}
                >
                  {renderInput("Account Holder Name", "bank_info", "account_holder_name", 50)}
                </Form.Item>
                <Form.Item
                  name={[ "bank_info", "branch"]}
                  rules={[
                    {
                      required: false,
                      validator: BlankSpacesValidator,
                    },
                  ]}
                >
                  {renderInput("Branch", "bank_info", "branch", 50)}
                </Form.Item>
              </div>
            </Card>
          </div>
          <div className="pt-3">
            <Card>
              <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Asset Information</p>
              {formData?.asset_info?.map((item, index) => {
              return(
              <div className="grid grid-cols-3 gap-x-5 gap-y-3">
                  <Form.Item name={["asset_info", index, "asset_registry"]}>
                  <div className='commonInputTlt'>Asset ID</div>
                    <Select
                      showSearch
                      value={item.asset_registry ? item.asset_registry : ""}
                      onChange={(value) => {
                        let newItems = [...formData.asset_info];
                        newItems[index]["asset_registry"] = value;
                        let assetName = assetList.find(data => data.id === value)
                        newItems[index]["asset_name"] = (assetName && Object.keys(assetName)?.length > 0) ? assetName.asset_name : "";
                        updateWholeFormData("asset_info", newItems);
                        form.setFields([
                          {
                            name: ['asset_info', index, "asset_registry"],
                            value: value,
                            errors: undefined,
                          },
                          {
                            name: ['asset_info', index, "asset_name"],
                            value: (assetName && Object.keys(assetName)?.length > 0) ? assetName.asset_name : "",
                            errors: undefined,
                          }
                        ])
                      }}
                      disabled = {(item.id && employeeId) || !isEditAccess}
                      allowClear={true}
                      filterOption={(input, option) =>
                         option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      ref={hideAssetRegRef}
                      onSelect={() => {
                        hideAssetRegRef.current.blur();
                      }}
                    >
                      {assetList.reduce((acc, option) => {
                        if (option.id === item.asset_registry || (!selectedAssetRegistryIds.includes(option.id) && !option.is_assigned && option.is_active)) {
                          acc.push(
                            <Select.Option key={option.id} value={option.id}> {option.asset_id + " - " + option.asset_name}</Select.Option>
                          );
                        }
                        return acc;
                      }, [])}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={["asset_info", index, "asset_name"]}
                    rules={[
                      {
                        required: false,
                        validator: BlankSpacesValidator,
                      },
                    ]}
                  >
                    {renderAssetInput("Asset Name", index, item, "asset_name", true)}
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "left" }}
                    name={["asset_info", index, "handing_over_by"]}
                  >
                    <div className='commonInputTlt'>Handover By</div>
                    <Select
                      showSearch
                      value={item.handing_over_by ? item.handing_over_by : ""}
                      onChange={(value) => handleAssetInfoChange("handing_over_by", index, value)}
                      allowClear={true}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled = {(item.id && employeeId) || !isEditAccess}
                      ref={hideHandByRef}
                      onSelect={() => {
                        hideHandByRef.current.blur();
                      }}
                    >
                      {rManager.map((option) => (
                        (option.id === item.handing_over_by || option.is_active) ?
                        <Select.Option key={option.id} value={option.id}>
                         {`${option.first_name ? option.first_name : "" } ${option.last_name ? option.last_name : "" } ${option.employee_id ? "-"+ option.employee_id : "" }`}
                        </Select.Option> : null
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={["asset_info", index, "issued_date"]}>
                  <div className='commonInputTlt'>Handover Date</div>
                    <DatePicker
                      key={index}
                      name={"issued_date"}
                      format={"DD-MM-YYYY"}
                      style={{width: "100%"}}
                      value={item?.issued_date ? dayjs(item.issued_date) : undefined}
                      onChange={(data) => handleAssetInfoChange("issued_date", index, data)}
                      disabledDate={disabledDateFrom}
                      inputReadOnly={true}
                      allowClear={true}
                      disabled = {(item.id && employeeId) || !isEditAccess}
                      placeholder = ""
                    />
                  </Form.Item>
                  <div class="col-span-2 text-left">
                    <Form.Item
                      name={["asset_info", index, "remarks"]}
                      rules={[
                        {
                          required: false,
                          validator: BlankSpacesValidator,
                        },
                      ]}
                    >
                      {renderAssetInput("Asset Notes", index, item, "remarks", ((item.id && employeeId)|| !isEditAccess) )}
                    </Form.Item>
                    {(index > 0 && (!(typeof item.id !== 'undefined' && employeeId) && isEditAccess)) ? 
                      <div className="flex justify-end">
                        <Button
                          onClick={() => handleRemove(index)}
                          icon={<MinusCircleOutlined className="plusIcon"/>}
                          style={{ border: 0 }}
                          size="large"
                        ></Button>
                      </div>
                     : null }
                  </div>
                </div>)})}
              {isEditAccess ? 
              <div className="flex flex-row justify-start">
                <Button
                  onClick={handleAdd}
                  icon={<PlusCircleOutlined className="plusIcon"/>}
                  style={{ border: 0 }}
                  size="large"
                ></Button>
                </div> : null}
            </Card>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center' style={{ height: "8%", backgroundColor: "white" }}>
        <MyButton htmlType="button" label="Back" onClick={onBack} outlined = {true} paddingX={"0 2vw"} marginRight={"0.625vw"} />
        <MyButton htmlType="submit" label={"Next"} disabled = {isLoading} loading={isLoading} paddingX={"0 2vw"} />
      </div>
    </Form>
  );
};

export default PersonalInfo;
