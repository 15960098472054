import { Card, Spin, Upload, Form, Button, Input, Table, Tooltip, Select, message, Modal } from "antd";
import ToastMsg from "components/common/ToastMsg";
import MyButton from "components/ui/Button/MyButton";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { imgUploadSize } from 'components/common/enum';
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from 'xlsx';
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployeeGroup } from "hooks/api/masterApi/employeeGroupApi";
import { getEmployeeShift } from "hooks/api/configurationApi/employeeShiftApi";
import { getUserGroup } from "hooks/api/adminApi/userGroupApi";
import { getEmployeeType } from "hooks/api/masterApi/employeeTypeApi";
import { genderEnum, relationshipEnum, titleEnum, bloodGroupEnum } from "components/common/enum";
import { ImagePaths } from "utils/ImagePath";
import IconButton from "components/ui/Button/IconButton";
import { CommonDeletePopup } from "components/common/CommonComponent";
import { MIGRATIONDATA } from "constants";
import { getCSVData, getMethod } from "components/common/CommonFuntion";
import { passwordValidator } from "components/common/validation";
import { useLocation, useNavigate } from "react-router-dom";
import { EMPLOYEE_MIGRATION_TEMPLATE } from "constants";

function MigrationTable() {
  const [loadings, setLoadings] = useState(false);
  const [isNotifyPopup, setIsNotifyPopup] = useState(false)
  const [csvFile, setCSVfile] = useState("")
  const navigate = useNavigate();
  const prevPropsRef = useRef();
  const refs = useRef({});
  const [sheetData, setSheetData] = useState([]);
  const [sorting, setSorting] = useState("");
  const [errors, setErrors] = useState({});
  const [tabledata, setTableData] = useState("");
  const [validateerror, setValidateError] = useState(Array(tabledata?.length).fill(true));
  const [formData, setFormData] = useState({
    attachment: [],
    totalcount: 0,
    fileName: "",
  });
  const authtoken = sessionStorage.getItem("token");
  const [form] = Form.useForm();
  const tableContainerHeight = "72vh";
  const [designationList, setdesignation] = useState([]);
  const [departmentlist, setdepartment] = useState([]);
  const [employeeGroupList, setemployeegroup] = useState([]);
  const [eShift, setEShift] = useState([]);
  const [eType, setEType] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [validation, setValidation] = useState([]);
  const hideCursorRef = useRef([]);
  const [deleteItem, setDeleteClicked] = useState({ index: undefined, isDeleteClicked: false });
  const location = useLocation();
  const { editDetails } = location.state || {};
  const [migratekey, setmigrate] = useState(null)
  const [disableProcess, setProcess] = useState(true);
  const [iscancelPop, setIsCancelPop] = useState(false);

  const handleClose = () => {
    setIsNotifyPopup(false);
  }

  const popupcancel = () => {
    setIsCancelPop(false);
  }

  const handleNavigate = () => {
    navigate("/datamigration/employeemigration/employeedetails", { state: { migration_id: migratekey } });
  }

  const handleBack = () => {
    navigate('/datamigration/employeemigration');
  }

  useEffect(() => {
    if (editDetails?.id != null) {
      setmigrate(editDetails?.id)
      getresponsemigrate(editDetails?.id)
    }
  }, [editDetails?.id])

  const NotificationPopup = () => {
    return (
      <Modal
        centered
        open={true}
        footer={null}
        width={"34vw"}
        className="custom-modal"
        closable={false}
      >
        <p id="common_weekoffTxt">Employee has been migrated successfully. Kindly map the Reporting Manager for the employee by clicking on 'Allocate Reporting Manager' button</p>
        <div className='flex justify-end items-center  mt-20'>
          <MyButton htmlType="button" label="Cancel" onClick={handleClose} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
          <MyButton htmlType="button" label="Allocate Reporting Manager" onClick={handleNavigate} loading={loadings} paddingX={"0 1.2vw"} />
        </div>
      </Modal>
    )
  }

  const cancelPopup = () => {
    return (
      <Modal
        centered
        open={true}
        footer={null}
        width={"25vw"}
        className="custom-modal"
        closable={false}
      >
        <p id="common_weekoffTxt">Are you sure want cancel this Process ?</p>
        <div className='flex justify-end items-center  mt-20'>
          <MyButton htmlType="button" label="NO" onClick={popupcancel} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
          <MyButton htmlType="button" label="Yes" onClick={handleCancel} loading={loadings} paddingX={"0 1.2vw"} />
        </div>
      </Modal>
    )
  }

  const handleDownload = () => {
    const queryString = ""
    getCSVData(EMPLOYEE_MIGRATION_TEMPLATE, queryString)
  }

  useEffect(() => {
    if(editDetails?.id != null){
      setmigrate(editDetails?.id)
      getresponsemigrate(editDetails?.id)
    }
  }, [editDetails?.id])

  /*call designation*/
  const getEmployeeDesignationList = async () => {
    try {
      const apiData = await getEmployeeDesignation(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setdesignation([...apiData.data])
      } else {
        setdesignation([])
      }
    } catch (error) {
      setdesignation([])
    }
  }
  /*call departments*/
  const getDepartmentList = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setdepartment(apiData.data?.length > 0 ? [...apiData.data] : [])

      } else {
        setdepartment([])
      }
    } catch (error) {
      setdepartment([])
    }
  }

  /*call employee group*/
  const getEmpGrpList = async () => {
    try {
      const apiData = await getEmployeeGroup(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setemployeegroup(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setemployeegroup([])
      }
    } catch (error) {
      setemployeegroup([])
    }
  }

  /* Employee shift */
  const employeeShift = async () => {
    try {
      const apiData = await getEmployeeShift(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEShift(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setEShift([])
      }
    } catch (error) {
      setEShift([])
    }
  };
  /* EMployee Type */
  const getEmployeeTypeList = async () => {
    setEType([])
    try {
      const apiData = await getEmployeeType(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEType(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setEType([])
      }
    } catch (error) {
      setEType([])
    }
  };

  const getUserGroupList = async () => {
    setUserGroup([])
    try {
      const apiData = await getUserGroup(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setUserGroup(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setUserGroup([])
      }
    } catch (error) {
      setUserGroup([])
    }
  };


  const getresponsemigrate = async (id) => {
    try {
      setLoadings(true)
      const apiData = await getMethod(`${MIGRATIONDATA}?migration_id=${id ? id : migratekey}`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setTableData([...apiData.data.employee_data]);
        setFormData({
          ...formData,
          conflict_no: apiData.data.conflict_records,
          processed_no: apiData.data.processed_records,
        })
        setLoadings(false)
      } else {
        setTableData([]);
        setLoadings(false)
      }
    } catch (error) {
      setTableData([]);
      setLoadings(false)
    }
  }

  useEffect(() => {
    getDepartmentList();
    getEmpGrpList();
    getEmployeeDesignationList();
    employeeShift();
    getEmployeeTypeList();
    getUserGroupList();
  }, [])



  const handleUploadChange = ({ fileList }) => {
    setLoadings(true)
    const validFileList = Array.isArray(fileList) ? fileList : [];
    setFormData({
      ...formData,
      attachment: validFileList,
    });

    if (validFileList?.length > 0) {
     
      const file = validFileList[0].originFileObj; // Access the actual file object (Blob)
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event.target.result;
        const workbook = XLSX.read(result, { type: 'binary' });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        setSheetData(data);

        const copyData = JSON.parse(JSON.stringify(data));
        const formattedData = [];

        // Get the headers from the first row
        const headers = copyData[2];
        if (copyData?.length < 103) {
          // Loop through each row (starting from the second row)
          for (let i = 3; i < copyData?.length; i++) {
            const row = copyData[i];
            // Check if the row is completely empty (all values are falsy)
            const isEmptyRow = row.every(cell => cell === null || cell === undefined || cell === '');

            // Skip empty rows
            if (!isEmptyRow) {
              const rowObject = {};
              rowObject["isError"] = validationProcess(row);
              for (let j = 0; j < row.length; j++) {
                let header = headers[j]; // Get the corresponding header for the current cell
                const cellData = row[j];   // Get the cell data

                if (header) {
                  header = header?.replace(/\*/g, '').replace(/\s+/g, ''); // Remove * and spaces
                }

                // If the header is related to date and cellData is a number, format it
                if (typeof cellData === 'number' && header && header.toLowerCase().includes('date')) {
                  rowObject[header] = XLSX.SSF.format('MM-DD-YYYY', cellData); // Format serial number as date
                } else {
                  rowObject[header] = cellData || null; // Assign the value to the header key, set null if empty
                }
              }

              // Push the row object to the formatted data array
              formattedData.push(rowObject);
            }
          }
        }
        else {
          setFormData({
            ...formData,
            fileName: "",
          })
          ToastMsg("error", "You can't Upload more than 100 employee details");
          setLoadings(false)
        }

        setTableData(formattedData); // Uncomment when needed
        setLoadings(false)
        // setUploaded(true); // Uncomment when needed
      };


      reader.readAsBinaryString(file); // Pass the single file (Blob) to FileReader
    }
  };
  const handleChange = (name, value, index) => {
    // Update the dataSource 
    const updatedDataSource = [...tabledata]; // Clone the dataSource
    updatedDataSource[index][name] = value; // Update the specific value by index
    updatedDataSource[index]["isError"] = validationProcess(updatedDataSource[index]);
    setTableData(updatedDataSource); // Set updated dataSource back
    
  };

  // const handleChange = (name, value, index) => {
  //   // Use a functional update to access the latest state
  //   setTableData((prevState) => {
  //     // Only update the specific row that needs to be changed
  //     const updatedRow = {
  //       ...prevState[index],
  //       [name]: value,
  //       isError: validationProcess({ ...prevState[index]}),
  //     };
  
  //     // Create a new array with the updated row
  //     const updatedData = [...prevState];
  //     updatedData[index] = updatedRow;
  
  //     return updatedData;
  //   });
  // }

  const handleDeleteClicked = (index) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      index: index,
      isDeleteClicked: true
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      index: undefined,
      isDeleteClicked: false
    }));
  }

  const deleteRow = (index) => {
    const newData = [...tabledata];
    newData.splice(index, 1); // Remove the row at the given index
    setTableData(newData);

    setDeleteClicked((prevdata) => ({
      ...prevdata,
      index: undefined,
      isDeleteClicked: false
    }));
  };
  const renderSelect = (statekey, value, list = [], showKey = "text", index, required = false, errorMessage = "") => {
    if (!hideCursorRef.current[statekey]) {
      hideCursorRef.current[statekey] = React.createRef();
    }

    // Check if the current value exists in the list
    const isValidValue = list.some(option => option[showKey] === value);

    return (
      <div>
        <Tooltip title={errorMessage}>
        <Select
          showSearch
          value={isValidValue ? value : ""}
          name={statekey}
          className="pl-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          onChange={(value) => handleChange(statekey, value, index)}
          filterOption={(input, option) => {
            const childrenText = option.children;
            return typeof childrenText === 'string' &&
              childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          ref={hideCursorRef.current[statekey]}
          onSelect={() => {
            hideCursorRef.current[statekey].current.blur();
          }}
          required
          style={{ border: isValidValue ? "1px solid #a0a2a4" : "1px solid red", borderRadius: "3px", boxShadow: isValidValue ? "0 0 7px -2px rgb(188 187 187)" : "0 0 7px -2px rgb(188 187 187)" }}
        >
          {
            list?.length > 0 && list.map((option) => (
                <Select.Option key={option[showKey]} value={option[showKey]}>
                    {option[showKey]}
                </Select.Option>
            ))}
                </Select>
            </Tooltip>
        </div>
    )
  }

  const handleTableChange = (pagination, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""

    setSorting(sortQuery)
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleprocess = () => {
    setLoadings(true);
    const updatedData = { data: tabledata,  migration_id: migratekey }
    fetch(MIGRATIONDATA, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${authtoken}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === "success") {
          message.success(data.message);
          await delay(1000);
          setFormData({
            ...formData,
            fileName: ""
          })
          setmigrate(data.migration_id)
          getresponsemigrate(data?.migration_id)
          setLoadings(false);
        } else if (data.status === "fail") {
          message.warning(data.message);
          setLoadings(false);
        }
      })
      .catch((error) => {
        error.json().then((data) => {
          setErrors(data);
          setLoadings(false);
        });
      });
    // setTimeout(() => {
    //   setLoadings(false);
    // }, 3000); // 2000ms = 2 seconds
  };

  const handlemigrate = () => {
    setLoadings(true);
    const updatedData = { data: tabledata, migrate: true, migration_id: migratekey };  // Add "migrate: true" to tabledata

    fetch(MIGRATIONDATA, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${authtoken}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === "success") {
          message.success(data.message);
          await delay(1000);
          setIsNotifyPopup(true)
          setLoadings(false);
        } else if (data.status === "fail") {
          message.warning(data.message);  
        }
      })
      .catch((error) => {
        error.json().then((data) => {
          setErrors(data);
          setLoadings(false);
        });
      });
    // setTimeout(() => {
    //   setLoadings(false);
    // }, 3000); // 2000ms = 2 seconds
  };

  const passwordValidator = (_, value) => {
    let userPasswordPolicy = JSON.parse(sessionStorage.getItem("passwordPolicy"));
    let passwordData = Object.keys(userPasswordPolicy)?.length > 0 ? userPasswordPolicy : {};
    
    if (!value) {
      return "This field is required";
    }

    if (!(passwordData.min_length <= value.length && value.length <= passwordData.max_length)) {
      return `Password should be between ${passwordData.min_length} and ${passwordData.max_length} characters`;
    }

    const numMatches = (value.match(/[0-9]/g) || []).length;
    if (!(passwordData.min_number <= numMatches && numMatches <= passwordData.max_number)) {
      return `Password should contain ${passwordData.min_number} to ${passwordData.max_number} numeric characters`;
    }

    const lowerMatches = (value.match(/[a-z]/g) || []).length;
    if (!(passwordData.min_lower <= lowerMatches && lowerMatches <= passwordData.max_lower)) {
      return `Password should contain ${passwordData.min_lower} to ${passwordData.max_lower} lowercase characters`;
    }

    const upperMatches = (value.match(/[A-Z]/g) || []).length;
    if (!(passwordData.min_upper <= upperMatches && upperMatches <= passwordData.max_upper)) {
      return `Password should contain ${passwordData.min_upper} to ${passwordData.max_upper} uppercase characters`;
    }

    const alphaMatches = lowerMatches + upperMatches;
    if (!(passwordData.min_chars <= alphaMatches && alphaMatches <= passwordData.max_chars)) {
      return `Password should contain ${passwordData.min_chars} to ${passwordData.max_chars} alphabetic characters`;
    }

    const specialMatches = value.match(/[^A-Za-z0-9]/g);
    if (!passwordData.password_combination && specialMatches) {
      return 'Password should not contain any special characters';
    }

    return null;  // No error
  };

  const validationProcess = (record) => {
    let errorKey = false;
    if (!record?.Title || record?.Title?.length > 5) {
      errorKey = true
    }
    if (!record?.FirstName || record?.FirstName?.length > 50) {
      errorKey = true
    }
    if (!record?.LastName || record?.LastName?.length > 50) {
      errorKey = true
    }
    if (!record?.PersonalEmailID || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(record?.PersonalEmailID.toString())) {
      errorKey = true
    }
    if (!record?.PersonalMobileNo || !/^\d{10}$/.test(record?.PersonalMobileNo.toString().trim())) {
      errorKey = true
    }
    if (!record?.ProfessionalEmailID || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(record?.ProfessionalEmailID.toString())) {
      errorKey = true
    }
    if (!designationList.some(option => option["designation_name"] === record?.Designation)) {
      errorKey = true
    }
    if (!departmentlist.some(option => option["department_name"] === record?.Department)) {
      errorKey = true
    }
    if (!eType.some(option => option["employee_type_name"] === record?.EmployeeType)) {
      errorKey = true;
    }
    if (!eShift.some(option => option["shift_name"] === record?.Shift)) {
      errorKey = true
    }
    if (!record?.DateOfJoining || !/^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/.test(record?.DateOfJoining.toString())) {
      errorKey = true
    }
    if (!record?.City || record?.City?.length > 50) {
      errorKey = true
    }
    if (!record?.State || record?.State?.length > 50) {
      errorKey = true
    }
    if (!record?.Pincode || record?.Pincode?.length > 50) {
      errorKey = true
    }
    if (!record?.PresentAddressCity || record?.PresentAddressCity?.length > 50) {
      errorKey = true
    }
    if (!record?.PresentAddressState || record?.PresentAddressState?.length > 50) {
      errorKey = true
    }
    if (!record?.PresentAddressPincode || record?.PresentAddressPincode?.length > 50) {
      errorKey = true
    }
    if (!userGroup.some(option => option["user_group_name"] === record?.Usergroup)) {
      errorKey = true;
    }
    if (!record?.Username || record?.Username?.length > 50) {
      errorKey = true;
    }
    if(passwordValidator("", record?.Password)){
      errorKey = true;
    } 
    if (!genderEnum.some(option => option["text"] === record?.Gender)) {
      errorKey = true
    }
    if (!record?.ContactPersonName || record?.ContactPersonName?.length > 50) {
      errorKey = true
    }
    
    if (!relationshipEnum.some(option => option["text"] === record?.RelationshipType)) {
      errorKey = true
    }
    if (!record?.ContactPersonMobileNo || !/^\d{10}$/.test(record?.ContactPersonMobileNo.toString().trim())) {
      errorKey = true
    }
    if (!employeeGroupList.some(option => option["employee_group_name"] === record?.EmployeeGroup)) {
      errorKey = true;
    }
    if (!bloodGroupEnum.some(option => option["text"] === record?.BloodGroup)) {
      errorKey = true
    }
   
    if (!record?.EmergencyContactPerson || record?.EmergencyContactPerson?.length > 50) {
      errorKey = true
    }
    if (!relationshipEnum.some(option => option["text"] === record?.EmergencyRelationshipType)) {
      errorKey = true
    }
    if (!record?.EmergencyContactNo || !/^\d{10}$/.test(record?.EmergencyContactNo.toString().trim())) {
      errorKey = true
    }
    return errorKey
  }

  const handleCancel = () =>{
    setFormData({
      attachment: [],
      totalcount: 0,
      fileName: "",
      processed_no: 0,
      conflict_no: 0,
    }); 
    setmigrate(null)
    setProcess(true);
    setTableData([])
    setIsCancelPop(false);
  }
  // console.log(tabledata)
  const columns = [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      sorter: true,
      width: "100px",
          render: (_, record, index) => {
              const isInvalid = (!record?.Title || record?.Title?.length > 5);
              const errorMessage = (record?.Title?.length > 5 ? "Title should not exceed 5 character" : isInvalid ? "Invalid Title" : null);
              return (
                  renderSelect("Title", record?.Title, titleEnum, "text", index, true, errorMessage)
              )
          }
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
      key: "FirstName",
      sorter: true,
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = (!record?.FirstName || record?.FirstName?.length > 50);
        const errorMessage = (record?.FirstName?.length > 50 ? "First Name should not exceed 50 character" : isInvalid ? "First Name should not be empty" : null);
        return (
          <Tooltip title={errorMessage}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"FirstName"}
              value={record?.FirstName}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"FirstName_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      key: "LastName",
      sorter: true,
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = (!record?.LastName || record?.LastName?.length > 50);
        const errorMessage = (record?.LastName?.length > 50 ? "Last Name should not exceed 50 character" : isInvalid ? "Last Name should not be empty" : null);
        return (
          <Tooltip title={errorMessage}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"LastName"}
              value={record?.LastName}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"LastName_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Personal Mail Id",
      dataIndex: "PersonalEmailID",
      key: "PersonalEmailID",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        const MailComment = record?.comments?.find(comment => comment.key === "PersonalEmailID");
        const isInvalid = !record?.PersonalEmailID || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(record?.PersonalEmailID.toString()) || !!MailComment;  // True if there's a validation comment
        const errorMessage = isInvalid
          ? (MailComment?.message || "Invalid Email id")  // Default error message if no comment
          : null;
        return (
          <Tooltip title={errorMessage}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"PersonalEmailID"}
              value={record?.PersonalEmailID}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"Emaild_id_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Personal Mobile No",
      dataIndex: "PersonalMobileNo",
      key: "PersonalMobileNo",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        const MobileComment = record?.comments?.find(comment => comment.key === "PersonalMobileNo");
        const isInvalid = !record?.PersonalMobileNo || !/^\d{10}$/.test(record?.PersonalMobileNo.toString().trim()) || !!MobileComment;  // True if there's a validation comment
        const errorMessage = isInvalid
          ? (MobileComment?.message || "Mobile No should be in 10 digit Numbers")  // Default error message if no comment
          : null;

        return (
          <Tooltip title={errorMessage}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"PersonalMobileNo"}
              value={record?.PersonalMobileNo}
              maxLength={10}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"Mobile_no_"${index}`}
            />}</Tooltip>

        )
      }
    },
    {
      title: "Professional Mail Id",
      dataIndex: "ProfessionalEmailID",
      key: "ProfessionalEmailID",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        const MailComment = record?.comments?.find(comment => comment.key === "ProfessionalEmailID");
        const isInvalid = !record?.ProfessionalEmailID || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(record?.ProfessionalEmailID.toString()) || !!MailComment;  // True if there's a validation comment
        const errorMessage = isInvalid
          ? (MailComment?.message || "Invalid Email id")  // Default error message if no comment
          : null;
        return (
          <Tooltip title={errorMessage}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"ProfessionalEmailID"}
              value={record?.ProfessionalEmailID}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"ProEmaild_id_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        const isInvalid = (!record?.Department);
        const errorMessage = (isInvalid ? "Department should not be empty" : null);
        return (
          renderSelect("Department", record?.Department, departmentlist, "department_name", index, true, errorMessage))
      }
    },
    {
      title: "Designation",
      dataIndex: "Designation",
      key: "Designation",
      sorter: true,
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = (!record?.Designation);
        const errorMessage = (isInvalid ? "Designation should not be empty" : null);
        return (
          renderSelect("Designation", record?.Designation, designationList, "designation_name", index, true, errorMessage))
      }
    },
    {
      title: "Employee Type",
      dataIndex: "EmployeeType",
      key: "EmployeeType",
      width: "160px",
      sorter: true,
      render: (_, record, index) => {
        const isInvalid = (!record?.EmployeeType);
        const errorMessage = (isInvalid ? "Employee Type should not be empty" : null);
        return (
          renderSelect("EmployeeType", record?.EmployeeType, eType, "employee_type_name", index, true, errorMessage)
        )
      }
    },
    {
      title: "Date Of Joining",
      dataIndex: "DateOfJoining",
      key: "DateOfJoining",
      width: "120px",
      sorter: true,
      render: (_, record, index) => {
        const isInvalid = (!record?.DateOfJoining || !/^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/.test(record?.DateOfJoining.toString()));
        const errorMessage = (isInvalid ? "Invalid Date (YYYY/MM/DD)" : null)
        return (
          <Tooltip title={errorMessage}>
            {<Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"DateOfJoining"}
              value={record?.DateOfJoining}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"DateOfJoining"${index}`}
            />}
          </Tooltip>
        )
      }
    },
    {
      title: "Shift",
      dataIndex: "Shift",
      key: "Shift",
      width: "120px",
      sorter: true,
      render: (_, record, index) => {
        return (
          renderSelect("Shift", record?.Shift, eShift, "shift_name", index, true)
        )
      }
    },
    {
      title: "City",
      dataIndex: "City",
      key: "City",
      sorter: true,
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.City || record?.City?.length > 50;

        return (
          <Tooltip City={"City Should not Exceed 50 Character"}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"City"}
              value={record?.City}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"City_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      sorter: true,
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.State || record?.State?.length > 50;


        return (
          <Tooltip State={"State Should not Exceed 50 Character"}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"State"}
              value={record?.State}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"State_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Pincode",
      dataIndex: "Pincode",
      key: "Pincode",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        const isInvalid = !record?.Pincode || !/^\d{6}$/.test(record?.Pincode.toString().trim());
        return (
          isInvalid ?
            <Tooltip title={"Pincode should be in 6 digit Numbers"}>{
              <Input
                autoComplete='off'
                className="alertbox"
                type={"text"}
                name={"Pincode"}
                value={record?.Pincode}
                maxLength={10}
                onChange={(e) => handleChange(e.target.name, e.target.value, index)}
                data-testID={`"Pincode"${index}`}
              />}</Tooltip>
            : <p><Input
              autoComplete='off'
              className="validatebox"
              type={"text"}
              name={"Pincode"}
              value={record?.Pincode}
              maxLength={10}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"Pincode"${index}`}
            /></p>
        )
      }
    },

    {
      title: "Present Address City",
      dataIndex: "PresentAddressCity",
      key: "PresentAddressCity",
      sorter: true,
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.PresentAddressCity || record?.PresentAddressCity?.length > 50;

        return (
          <Tooltip PresentAddressCity={"PresentAddressCity Should not Exceed 50 Character"}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"PresentAddressCity"}
              value={record?.PresentAddressCity}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"PresentAddressCity_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Present Address State",
      dataIndex: "PresentAddressState",
      key: "PresentAddressState",
      sorter: true,
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.PresentAddressState || record?.PresentAddressState?.length > 50;

        return (
          <Tooltip PresentAddressState={"PresentAddressState Should not Exceed 50 Character"}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"PresentAddressState"}
              value={record?.PresentAddressState}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"PresentAddressState_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Present Address Pincode",
      dataIndex: "PresentAddressPincode",
      key: "PresentAddressPincode",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        const isInvalid = !record?.PresentAddressPincode || !/^\d{6}$/.test(record?.PresentAddressPincode.toString().trim());
        return (
          isInvalid ?
            <Tooltip title={"PresentAddressPincodeshould be in 6 digit Numbers"}>{
              <Input
                autoComplete='off'
                className="alertbox"
                type={"text"}
                name={"PresentAddressPincode"}
                value={record?.PresentAddressPincode}
                maxLength={10}
                onChange={(e) => handleChange(e.target.name, e.target.value, index)}
                data-testID={`"PresentAddressPincode"${index}`}
              />}</Tooltip>
            : <p><Input
              autoComplete='off'
              className="validatebox"
              type={"text"}
              name={"PresentAddressPincode"}
              value={record?.PresentAddressPincode}
              maxLength={10}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"PresentAddressPincode"${index}`}
            /></p>
        )
      }
    },
    {
      title: "User group",
      dataIndex: "Usergroup",
      key: "Usergroup",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        return (
          renderSelect("Usergroup", record?.Usergroup, userGroup, "user_group_name", index, true)
        )
      }
    },
    {
      title: "User Name",
      dataIndex: "Username",
      key: "Username",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        const usernameComment = record?.comments?.find(comment => comment.key === "username");
        const isInvalid = !record?.Username || !!usernameComment || record?.Username?.length > 50;  // True if there's a validation comment
        const errorMessage = isInvalid
          ? (usernameComment?.message || "User Name Should not Exceed 50 Characters")  // Default error message if no comment
          : null;  // No error message if valid
        // const isInvalid = !record?.Username || record?.Username?.length > 15;
        return (
          <Tooltip Title={errorMessage}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"Username"}
              value={record?.Username}
              maxLength={15}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"Username_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Password",
      dataIndex: "Password",
      key: "Password",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
       const errorMessage = passwordValidator(_, record?.Password);
       const isInvalid = !!errorMessage; // Check if there's an error
        // const errorMessage = isInvalid ? "Password is invalid!" : "";
        return (
          <Tooltip
            title={errorMessage}  // Error message
            placement="top"     // Position of the tooltip
          >
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"Password"}
              value={record?.Password}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"Password_"${index}`}
            /></Tooltip>
        )
      }
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      sorter: true,
      width: "120px",
      render: (_, record, index) => {
        const isInvalid = !record?.Gender;
        return (
          <Tooltip title={"Select Gender"}>
            {renderSelect("Gender", record?.Gender, genderEnum, "text", index, true)}</Tooltip>
        )
      }
    },
    {
      title: "Contact Person Name",
      dataIndex: "ContactPersonName",
      key: "ContactPersonName",
      sorter: true,
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.ContactPersonName || record?.ContactPersonName?.length > 50;
        return (
          <Tooltip Title={"Conatct person name Should not Exceed 50 Character"}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"ContactPersonName"}
              value={record?.ContactPersonName}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"ContactPersonName_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Relationship",
      dataIndex: "RelationshipType",
      key: "RelationshipType",
      sorter: true,
      width: "120px",
      render: (_, record, index) => {
        const isInvalid = !record?.RelationshipType;
        return (
          <Tooltip title={"Select RelationshipType"}>
            {renderSelect("RelationshipType", record?.RelationshipType, relationshipEnum, "text", index, true)}</Tooltip>
        )
      }
    },
    {
      title: "Contact Person Mobile No",
      dataIndex: "ContactPersonMobileNo",
      key: "ContactPersonMobileNo",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        const isInvalid = !record?.ContactPersonMobileNo || !/^\d{10}$/.test(record?.ContactPersonMobileNo.toString().trim());
        return (
          <Tooltip title={"Mobile No should be in 10 digit Numbers"}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"ContactPersonMobileNo"}
              value={record?.ContactPersonMobileNo}
              maxLength={10}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"Mobile_no_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Employee Group",
      dataIndex: "EmployeeGroup",
      key: "EmployeeGroup",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        return (
          renderSelect("EmployeeGroup", record?.EmployeeGroup, employeeGroupList, "employee_group_name", index, true)
        )
      }
    },
    {
      title: "Blood Group",
      dataIndex: "BloodGroup",
      key: "BloodGroup",
      sorter: true,
      width: "120px",
      render: (_, record, index) => {
        const isInvalid = !record?.BloodGroup;
        return (
          <Tooltip title={"Select BloodGroup"}>
            {renderSelect("BloodGroup", record?.BloodGroup, bloodGroupEnum, "text", index, true)}</Tooltip>
        )
      }
    },
    {
      title: "Emergency Contact Person",
      dataIndex: "EmergencyContactPerson",
      key: "EmergencyContactPerson",
      sorter: true,
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.EmergencyContactPerson || record?.EmergencyContactPerson?.length > 50;
        return (
          <Tooltip Title={"Conatct person name Should not Exceed 50 Character"}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"EmergencyContactPerson"}
              value={record?.EmergencyContactPerson}
              maxLength={50}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"EmergencyContactPerson_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Emergency Contact No",
      dataIndex: "EmergencyContactNo",
      key: "EmergencyContactNo",
      width: "150px",
      sorter: true,
      render: (_, record, index) => {
        const isInvalid = !record?.EmergencyContactNo || !/^\d{10}$/.test(record?.EmergencyContactNo.toString().trim());
        return (
          <Tooltip title={"Emergency Contact No should be in 10 digit Numbers"}>{
            <Input
              autoComplete='off'
              className={isInvalid ? "alertbox" : "validatebox"}
              type={"text"}
              name={"EmergencyContactNo"}
              value={record?.EmergencyContactNo}
              maxLength={10}
              onChange={(e) => handleChange(e.target.name, e.target.value, index)}
              data-testID={`"Mobile_no_"${index}`}
            />}</Tooltip>
        )
      }
    },
    {
      title: "Emergency Relationship",
      dataIndex: "EmergencyRelationshipType",
      key: "EmergencyRelationshipType",
      sorter: true,
      width: "120px",
      render: (_, record, index) => {
        const isInvalid = !record?.EmergencyRelationshipType;
        return (
          <Tooltip title={"Select EmergencyRelationshipType"}>
            {renderSelect("EmergencyRelationshipType", record?.EmergencyRelationshipType, relationshipEnum, "text", index, true)}</Tooltip>
        )
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "100px",
      render: (_, record, index) => {
        return (
          <Tooltip title={"Delete"}>
            <IconButton
              title="Delete"
              icon={<img src={ImagePaths.delete.default} alt="Delete" />}
              onClick={() => (handleDeleteClicked(index))}
              testID={'ug_btn_delete'}
            />
          </Tooltip>
        );
      },
    },
  ];
  if (migratekey) {
    const commentdata = {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      width: "150px", 
      render: (_, record, index) => {
        const mergedComments = record.comments
          ?.map(comment => comment.message)
          .join(", ") || "";

        return (
          <Tooltip title={mergedComments}>
            <p>{mergedComments?.slice(0, 15) + "..."}</p>
          </Tooltip>
        );
      },
    }

    // Insert the Comment right before the last element
    columns.splice(columns.length - 1, 0, commentdata);
  }

    useEffect(() => {
        if (tabledata.length > 0) {
            let errorIndex = -1;
            for (let i = 0; i < tabledata.length; i++) {
                if (tabledata[i].isError === true) {
                    errorIndex = i;
                    break;
                }
            }
            if (errorIndex !== -1) {
                setProcess(true)
            } else {
                setProcess(false)
            }
        }
    }, [tabledata])


  return (
    <div className="h-full">
      <Card className="p-3">
        {/* {loadings && (
                    <div className="loaderOverlay">
                        <Spin />
                    </div>
                )} */}

        <div className='flex items-center'>
          <Tooltip title={"Back"} className="mr-2" placement="bottom">
            <img style={{ cursor: "pointer" }} src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBack} />
          </Tooltip>
          <p className='common_tltTxt'>{`Employees (${tabledata?.length})`}</p>
        </div>
        <div className="mt-7 flex align-center justify-between" >
          <div className="flex gap-5">
            <MyButton onClick={handleDownload} label={"Download Sheet"} />
            <div className="flex items-center justify-between border border-gray-300 rounded-sm p-2 w-full" style={{ width: "20dvw", height: "6dvh" }}>
              <span style={{ width: "16dvw", overflow: "hidden" }} className="text-gray-500">{formData?.fileName ? formData?.fileName : "Browse Files"}</span>
              <Upload
                disabled={migratekey ? true : false}
                id={`notice_board_attachment`}
                maxCount={1}
                onChange={handleUploadChange}
                beforeUpload={(file) => {               
                  const isCSV = file.type === 'text/csv'; // Correct MIME type for CSV files
                  if (!isCSV) {
                    ToastMsg("error", "You can only upload CSV files!");
                    return false;
                  }
                  const isWithinSizeLimit = file.size / 1024 / 1024 < imgUploadSize;
                  if (!isWithinSizeLimit) {
                    ToastMsg("error", `File must be smaller than ${imgUploadSize}MB!`);
                    return false;
                  }
                  setFormData({
                    ...formData,
                    fileName: file.name,
                  })
                  return false;
                }}
                showUploadList={false}
                accept=".csv">
                <Button
                  type="text"
                  icon={<UploadOutlined />}
                  className="bg-white border-none"
                  style={{ border: "1px solid #afacac", paddingLeft: "7px", paddingTop: "2px" }}
                />
              </Upload>
            </div>
            {migratekey ?
              <div>
                <p class="processrec">Processed records : {formData?.processed_no}</p>
                <p class="conflictrec">Conflict records : {formData?.conflict_no}</p>
              </div> : null
            }
          </div>
          <div>
            <MyButton label={"Cancel"} onClick = {() => setIsCancelPop(true)} />
            <MyButton className="ml-5" type="button" disabled={tabledata.length <= 0 || loadings || disableProcess} onClick={handleprocess} label={"Process"} bgColor={disableProcess ? "#d6d6d6" : "#334B49"} />
            <MyButton className="ml-5" onClick={editDetails?.status === "completed" ? handleNavigate : handlemigrate} style={{ backgroundColor: "grey" }} label={editDetails?.status === "completed" ?  "Allocate Report Manager" : "Migrate"} bgColor={(tabledata.length > 0 || !migratekey || formData?.conflict_no != 0) ? "#d6d6d6" : "#334B49"} disabled={loadings || tabledata.length > 0 || !migratekey || formData?.conflict_no != 0 }  />
          </div>
        </div>
      </Card>
      <Card className="mt-2 h-full">
        <Table
          style={{ "--table-container-height": tableContainerHeight }}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          columns={columns}
          dataSource={tabledata?.length > 0 ? tabledata : null}
          loading={loadings}
          onChange={handleTableChange}
          pagination={false
          }
        />
        {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => deleteRow(deleteItem.index)} /> : null}
      </Card>
      {isNotifyPopup ? NotificationPopup() : null}
      {iscancelPop ? cancelPopup() : null}
    </div>
  )
}


const styles = {
  divStyle: {
    marginBottom: "1vw",
    paddingBottom: "1.5vw"
  },
  tltTxt: {
    paddingBottom: "0.75vw"
  },
  uploadInput: {
    flex: 1,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    paddingRight: 0,
  },
  uploadBtn: {
    height: "100%",
    boxShadow: 0,
    fontFamily: "Urbanist",
    backgroundColor: "#cbcbcb",
    borderColor: "#cbcbcb",
    border: "none",
    padding: "0.3vw",
    margin: 0,
    color: "#183433",
    fontSize: '0.85vw',
    fontWeight: "bold",
  },

}


export default MigrationTable;